.test_protect {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* font-size: 20px; */
}

.test_protect button {
  color: #15afd0;
  background: none;
  border: 1px solid #15afd0 !important;
  border-radius: 2px;
  margin-top: 5px;
  float: right;
}

.test_protect input {
  width: 100%;
  background: #2d2f31;
  height: 30px;
  border: 1px solid #3f4c58 !important;
  border-radius: 5px;
  padding: 2px;
}
