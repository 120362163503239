.customHeader{
    height:auto;
    position:fixed;
    padding: 0px;
    background:#0a0c0f;
    display:flex;
    flex-direction:row;
    top:0;left:0;right:0;
    z-index:9;
}   

.logo {
    width: 150px;
    height: 60px;
    background: rgba(255, 255, 255, 0);
    margin: 16px 16px 16px 16px;
    float: left;
}

.customMenu{
    letter-spacing:1px;
    font-size:14px;
    padding: 0 12px;
    flex-grow: 1;
    flex-shrink: 0;
    text-align:center;
}

.customSider{
    border-left:1px solid rgba(255,255,255,0.15);
}

.ant-menu-horizontal {
    border-bottom: 0px;
}

.ant-menu-horizontal > .ant-menu-item.customMenu:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active.customMenu, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open.customMenu, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected.customMenu, .ant-menu-horizontal > .ant-menu-submenu-selected{
    background:#181b20!important;
    color:#15afd0!important;
    border-color: transparent;
}

.ant-table-body{
    margin: 0px!important;
}

.ant-modal-close{
    display:none;
}

.ant-table-thead > tr > th{
    background:transparent!important;
    color:#FFF!important;
    vertical-align: middle;
    height: 30px;
}

.ant-table-small{
    border:0px!important;
    border-radius: 0px;
}

.ant-table-thead > tr > th{
    border-bottom:1px solid rgb(24, 27, 32);
    color:#15afd0!important;
    border-right: 0px solid rgba(255,255,255,0.1);
}

.ant-table-thead > tr > th:first-child, .ant-table-tbody > tr > td:first-child {
    padding-left:0px!important;
}

.ant-table-tbody > tr:nth-child(odd) > td{
    /*background-color: rgb(24, 27, 32)!important;*/
}

.ant-table-thead > tr > th:last-child, .ant-table-tbody > tr > td:last-child {
    padding-right:0px!important;
}

.ant-table-tbody > tr > td {
    border-bottom:0px solid rgba(255,255,255,0.1);
    border-right: 0px solid rgba(255,255,255,0.1);
    color: rgba(255,255,255,0.75);
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td{
    padding-top: 3px!important;
    padding-bottom:3px!important;
    font-size: 12px;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td{
    background: #15afd045;
    color: #FFF;
}

.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
    background:transparent;
}

.ant-table-small > .ant-table-content .ant-table-header{
    background: transparent;
}

.fixed-header-section .ant-table-placeholder{
    display:none;
}

.custom-scroll::-webkit-scrollbar, ::-webkit-scrollbar {
    width: 8px;
}
 
.custom-scroll::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.custom-scroll::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
  background-color: #15afd0;
}

svg.blue{
    fill:#15afd0;
}

.hover-tile:hover{
    border:1px solid #1890ff2e;
    /*background:rgba(255,255,255,0.09)!important;*/
    cursor:pointer;
    transform: scale(1.02);
}


.hover-tile{
    transition: transform ease 200ms;
}

.content-section:hover{
    margin-right:10px;
}

.custom-scroll{
    overflow-x: hidden;
    overflow-y: hidden;
}


.custom-scroll-x{
    overflow-x: auto;
    overflow-y: hidden;
}

.custom-scroll-x:hover{
    overflow-x: auto;
}

.custom-scroll-y:hover{
    overflow-y: auto;
}

svg text{
    fill:#FFF;
    font-size: 11px;
    font-family: 'Arial'
}

.ant-select-selection{
    background:transparent;
    border: 1px solid rgba(255,255,255,0.25);
}

.ant-row{
    position:relative;
}

.hidden-labels #sigma-group-labels text{
    visibility: hidden!important;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder, .ant-select-selection-selected-value{
    /*font-size: 12px;
    line-height: 28px;*/
}

.ant-select-arrow .ant-select-arrow-icon{
    color:#FFF;
}

.ant-modal-footer{
    display:none;
}

svg .arc polyline{
    stroke:transparent;
}

svg .arc:hover{
    transform:scale(1.03);
}

svg .arc:nth-child(6) path{
    fill:#fff;
}

svg .arc:nth-child(5) path{
    fill:#15c4ec;
}

svg .dot[fill="#e6550d"]{
    fill:#15c4ec;
}

svg .dot[fill="#fd8d3c"]{
    fill:#FFF;
}

svg .bar[fill="#e6550d"]{
    fill:#15c4ec;
}

svg .bar[fill="#fd8d3c"]{
    fill:#FFF;
}

.ant-modal-content{
    padding:0;
    box-shadow: none;
    background: transparent;
}

.ant-modal-body{
    padding:0;
}

.ant-modal-footer{
    border-top:1px solid rgba(8, 150, 214, 0.33);
}

.ant-btn{
    min-width: 100px;
}

.ant-input{
    background: transparent;
    color:#FFF;
    border: 1px solid rgba(8, 150, 214, 0.33);
}

.tile-right, .active-tile{
    position:relative;
}

.tile-right:after{
    content:"";
    display:block;
    position: absolute;
    top:100%;
    left:50%;
    transform: translateX(-50%);
    width: 1px;
    height:25px;
    background: rgba(24, 144, 255, 0.32);
}


.active-tile:after{
    content:"";
    display:block;
    position: absolute;
    top:50%;
    left:100%;
    transform: translateY(-50%);
    width: 15px;
    height:1px;
    background: rgba(24, 144, 255, 0.32);
}

.ant-select-selection{
    border:1px solid rgba(8, 150, 214, 0.33)!important;
}

.ant-menu:before, .ant-menu:after{
    content: none;
}

.cell {
    stroke:#181b20;
    stroke-width: 1px;
    cursor: pointer;
    position:relative;
    z-index:1;
}

.cell:hover{
    stroke: rgba(255,255,255,0.05);
    z-index:2;
}

.cell2 {
    stroke: rgba(255,255,255,0.08);
    stroke-width: 1px;
    position:relative;
    z-index:2;
    pointer-events: none;
}

.textLabel{
    color:#FFF;
    opacity:0.15;
    font-size:64px;
    font-weight:bold;
}

.group{
    stroke-width: 2px;
}

.label {
    opacity:0;
    text-anchor: middle;
    fill: white;
  }
  
  .label>.name {
    dominant-baseline: text-after-edge;
    font-size:8px;
  }

  #voronoi-chart {
      padding: 20px 30px;
  }

  #voronoi-chart svg{
      width:100%;
      height:100%;
  }

  #voronoi-chart svg g{
      border: 1px solid red;
  }
  
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 2px solid #111419;
}

.ant-collapse:not(.ant-collapse-icon-position-left) > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left:auto;
    right: 16px;
}

.ant-collapse:not(.ant-collapse-icon-position-left) > .ant-collapse-item > .ant-collapse-header{
    padding: 8px 17px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding: 0px;
}

.ant-tree li{
    padding: 1px 0
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255,255,255,0.1);
}

table{
    padding-left:0!important;
    padding-right:0!important;
}

.ant-progress-circle-trail{
    stroke: rgba(255,255,255,0.05)!important;
}

.hover-button{
    opacity: 0.90;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}

.hover-button:hover{
    opacity:1;
}

.custom-tabs.ant-tabs .ant-tabs-small-bar .ant-tabs-tab{
    padding: 8px 15px 10px;
    margin-right: 0px;
    margin-left:15px;
    margin-bottom:0px;
    border-bottom:0;
    line-height: 100%;
    font-size: 13px;
    background:rgba(255,255,255,0.05);
    border-radius:5px;
}

.custom-tabs.ant-tabs .ant-tabs-left-bar .ant-tabs-tab{
    background: transparent;
    margin-left: 17px;
    padding-right:5px;
    padding-bottom:3px;
    font-size: 12px;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active{
    border-bottom:0px;
    font-weight:normal;
}

.custom-tabs.ant-tabs-top .ant-tabs-ink-bar-animated, .custom-tabs.ant-tabs-bottom .ant-tabs-ink-bar-animated{
    display:none!important;
}

.custom-tabs .ant-tabs-bar.ant-tabs-top-bar{
    border-bottom: 0px solid rgba(255,255,255,0.1);
    border-top: 0px solid rgba(255,255,255,0.1);
    margin-bottom:10px;
}

.ant-tabs .ant-tabs-left-content{
    border:0px;
    padding-top: 5px;
    padding-left:14px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.ant-tabs .ant-tabs-left-bar{
    border-right:0px solid rgba(255,255,255,0.1);
}

.pulse-button{
    animation: shadow-pulse 2s infinite;
}

.untouchable{
    pointer-events: none;
}

@keyframes shadow-pulse
{
     0% {
          box-shadow: 0 0 0 0px #13839c73;
     }
     100% {
          box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
     }
}

.custom-canvasjs{
    position:relative;
}

.custom-canvasjs:after{
    position:absolute;
    content:"";
    bottom:0;
    width:65px;
    left:0;
    height:15px;
    background: #181b20
}

.canvasjs-chart-credit{
    display:none!important;
}

.vertical-text{
    margin:0;
    flex-grow:1;
    text-align:center;
    writing-mode:tb-rl;
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform:rotate(180deg);
    transform: rotate(180deg);
    white-space:nowrap;
    display:block;
    bottom:0;
    width:20px;
    height:20px;
}

.custom-table-large .ant-table-tbody > tr > td, .custom-table-large .ant-table-tbody > tr > td:first-child{
    max-width:200px!important;
    padding:12px!important;
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    border-bottom:1px solid rgba(255,255,255,0.1)!important;
    border-right:1px solid rgba(255,255,255,0.1)!important;
}

.highlighted{
    box-shadow: 0 0 10px #15c4eccc;
}

.ant-progress-line .ant-progress-inner{
    background:transparent;
}

.ant-affix{
    background:#111419;
}

.hover-section:hover{
    background:rgba(255,255,255,0.03);
    border-radius: 15px;
}

.ant-table-thead > tr > th:first-child, .ant-table-tbody > tr > td:first-child{
    padding-left: 24px!important;
}

.ant-table-thead > tr > th:last-child, .ant-table-tbody > tr > td:last-child{
    padding-right: 24px!important;
}

.custom-tab svg{
    overflow: visible!important;
}